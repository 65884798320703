import React, { useEffect } from "react";
import aquarium from "../assets/aquarium.png";
import beabo from "../assets/beabo.png";
import gurubox from "../assets/guru-box.png";
import homeman from "../assets/homeman.png";
import logo from "../assets/logo-small.png";

const Page6Content = ({ selectedOptions, handleOptionChange }) => {
  const options = [
    "Производство",
    "Строительство",
    "Торговля",
    "Сфера уcлуг",
    "Туризм",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[0px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white font-[600] text-[20px] text-center">
          Готовые решения
        </h1>
        <div className=" max-w-[120px]">
          <img src={aquarium} alt="aquarium" className="rotate-[-2.02deg]" />
        </div>
        <div className="flex flex-col gap-[8px] max-w-[800px]">
          <div className="flex items-center justify-start gap-[14px]">
            <div className="flex-shrink-0 w-10">
              <img src={gurubox} alt="gurubox" className="rounded-[8px]" />
            </div>
            <div className="flex flex-col items-start">
              <div className="text-white font-[600] text-[16px] tracking-[2%]">
                Гурубокс
              </div>
              <div className="max-w-[500px] text-white font-[400] text-[11px] tracking-[2%]">
                Интеграционный модуль для создания баз знаний на основе опыта
                людей и создания цифровых помощников и рекомендательных систем.
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start gap-[14px]">
            <div className="flex-shrink-0  w-10">
              <img src={homeman} alt="homeman" className="rounded-[8px]" />
            </div>
            <div className="flex flex-col items-start">
              <div className="text-white font-[600] text-[16px] tracking-[2%]">
                Домовой
              </div>
              <div className="max-w-[500px] text-white font-[400] text-[11px] tracking-[2%]">
                Программный модуль и контроллер для создания самосоздающих
                сценариев управления системами умного дома.
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start gap-[14px]">
            <div className="flex-shrink-0  w-10">
              <img src={beabo} alt="beabo" className="rounded-[8px]" />
            </div>
            <div className="flex flex-col items-start">
              <div className="text-white font-[600] text-[16px] tracking-[2%]">
                Be Abo digital guide
              </div>
              <div className="max-w-[500px] text-white font-[400] text-[11px] tracking-[2%]">
                Сервис организации персонифицированных самостоятельных маршрутов
                путешествий.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start">
          <div className="text-white font-[600] text-[16px] mt-[12px] mb-[4px]">
            Ваши кастомизированные решения
          </div>
          <div className="text-white font-[400] text-[11px] mb-[10px]">
            это IT решения, для которых нет на рынке подходящих готовых, в том
            числе под ваши идеи и стартапы
          </div>

          <div className="flex flex-col w-full">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className="flex items-center gap-[6px] cursor-pointer py-[6px] border-b-[1px] border-white border-opacity-[15%]"
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[600] text-[11px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[600] text-[14px] mt-[8px] mb-[9px]">
            Ваша отрасль
          </div>
        </div>
        <div className="w-full flex items-center px-[12px] py-[14px] gap-[10px] border-[1px] rounded-[12px] mb-[20px]">
          <input
            type="text"
            placeholder="Например, образование"
            className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px]"
          />
        </div>
      </div>

      {/* Десктоп */}
      <div className="hidden md:flex justify-between items-start  gap-[20px]">
        <div className="max-w-[832px] mt-[20px] flex justify-between gap-[46px]">
          <div className=" max-w-[400px]">
            <h1 className="text-white font-[600] text-[24px] mb-[16px]">
              Готовые решения
            </h1>

            <div className="flex flex-col gap-[18px] max-w-[800px]">
              <div className="flex items-center justify-start gap-[10px]">
                <div className="flex-shrink-0 max-w-[50px]">
                  <img src={gurubox} alt="gurubox" className="rounded-[8px]" />
                </div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[16px] tracking-[2%]">
                    Гурубокс
                  </div>
                  <div className="max-w-[400px] text-white font-[400] text-[12px] tracking-[2%]">
                    Интеграционный модуль для создания баз знаний на основе
                    опыта людей и создания цифровых помощников и
                    рекомендательных систем.
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start gap-[10px]">
                <div className="flex-shrink-0 max-w-[50px]">
                  <img src={homeman} alt="homeman" className="rounded-[8px]" />
                </div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[16px] tracking-[2%]">
                    Домовой
                  </div>
                  <div className="max-w-[380px] text-white font-[400] text-[12px] tracking-[2%]">
                    Программный модуль и контроллер для создания самосоздающих
                    сценариев управления системами умного дома.
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start gap-[10px]">
                <div className="flex-shrink-0 max-w-[50px]">
                  <img src={beabo} alt="beabo" className="rounded-[8px]" />
                </div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[16px] tracking-[2%]">
                    Be Abo digital guide
                  </div>
                  <div className="max-w-[500px] min-w-[200px] text-white font-[400] text-[12px] tracking-[2%]">
                    Сервис организации персонифицированных самостоятельных
                    маршрутов путешествий.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="min-w-[260px] max-w-[260px]">
            <div className="text-white font-[600] text-[20px] mt-[8px] mb-[6px]">
              Ваши кастомизированные решения
            </div>
            <div className="text-white font-[400] text-[12px] mb-[10px]">
              это IT решения, для которых нет на рынке подходящих готовых, в том
              числе под ваши идеи и стартапы
            </div>

            <div className="flex flex-col">
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionChange(option)}
                  className="flex items-center gap-[12px] cursor-pointer py-[8px] border-b-[1px] border-white border-opacity-[15%]"
                >
                  <div
                    className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                      selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                    }`}
                  ></div>
                  <span className="text-white font-[600] text-[14px]">
                    {option}
                  </span>
                </div>
              ))}
            </div>

            <div className="text-white font-[600] text-[18px] mt-[18px] mb-[12px]">
              Ваша отрасль
            </div>

            <div className="w-full h-[46px] lg:w-[240px] flex items-center px-[12px] py-[12px] gap-[10px] border-[1px] rounded-[12px] mb-[10px]">
              <input
                type="text"
                placeholder="Например, образование"
                className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px]"
              />
            </div>
          </div>
        </div>

        <div>
          <img
            src={aquarium}
            alt="aquarium"
            className="rotate-[-2.02deg] min-w-[300px] max-w-[400px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Page6Content;
