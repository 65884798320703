import React, { useEffect } from "react";
import ram from "../assets/ram.png";
import logo from "../assets/logo-small.png";

const Page2Content = ({ selectedOptions, handleOptionChange }) => {
  const options = [
    "Как вообще мне это может быть полезно?...",
    "Наверное мне нужно, но непонятно как…",
    "Мне точно надо, но нужны советы эксперта",
    "Вы вообще о чем тут говорите?...",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Версия для мобильных экранов */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[4px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white text-center font-[600] text-[20px] mb-[4px]">
          Не проиграть конкуренцию
        </h1>
        <div className="max-w-[100px]">
          <img src={ram} alt="ram" />
        </div>
        <div className="text-white font-[400] text-[12px] mb-[8px]">
          Скоро искусственный интеллект распространиться везде, как
          электричество в начале 20 века.
        </div>
        <div className="text-white font-[400] text-[12px] mb-[8px]">
          Вам надо принимать эти изменения и применять у себя, чтобы не остаться
          за бортом истории!
        </div>
        <div>
          <div className="text-white font-[400] text-[14px] mb-[8px]">
            Сейчас директора и владельцы думают так:
          </div>

          <div className="flex flex-col gap-[8px] max-w-[750px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className="flex items-center gap-[12px] cursor-pointer pb-[6px] border-b-[1px] border-white border-opacity-[15%]"
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[400] text-[11px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[12px] mt-[12px] mb-[8px]">
            А к какому типу  относитесь вы? Выберите свой вариант в списке
          </div>
          <div className="text-white font-[400] text-[12px] mb-[8px]">
            Мы и есть эти эксперты, которые помогут вам быть эффективным и
            конкурентным путем применения современных IT технологий с
            искусственным интеллектом.
          </div>
        </div>
      </div>

      {/* Версия для десктопа экранов */}
      <div className="hidden md:flex justify-between items-start gap-[16px]">
        <div className="max-w-[758px] mt-[20px]">
          <h1 className="text-white font-[600] text-[28px] mb-[16px]">
            Не проиграть конкуренцию
          </h1>
          <div className="text-white font-[400] text-[14px] mb-[6px]">
            Скоро искусственный интеллект распространиться везде, как
            электричество в начале 20 века.
          </div>
          <div className="text-white font-[400] text-[14px] mb-[16px]">
            Вам надо принимать эти изменения и применять у себя, чтобы не
            остаться за бортом истории!
          </div>
          <div>
            <div className="text-white font-[400] text-[20px] mb-[10px]">
              Сейчас директора и владельцы думают так:
            </div>

            <div className="flex flex-col gap-[8px] max-w-[750px]">
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionChange(option)}
                  className="flex items-center gap-[12px] cursor-pointer pb-[8px] border-b-[1px] border-white border-opacity-[15%]"
                >
                  <div
                    className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                      selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                    }`}
                  ></div>
                  <span className="text-white font-[400] text-[14px]">
                    {option}
                  </span>
                </div>
              ))}
            </div>

            <div className="text-white font-[400] text-[18px] mt-[12px] mb-[8px]">
              А к какому типу  относитесь вы? Выберите свой вариант в списке.{" "}
            </div>
            <div className="text-white font-[400] text-[16px] mb-[26px]">
              Мы и есть эти эксперты, которые помогут вам быть эффективным и
              конкурентным путем применения современных IT технологий с
              искусственным интеллектом.
            </div>
          </div>
        </div>
        <div>
          <img src={ram} alt="ram" className="min-w-[360px] max-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page2Content;
