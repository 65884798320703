import React from "react";

const ButtonsBlock = ({
  onButtonNextClick,
  onButtonBackClick,
  isBack,
  text,
  isLastPage,
  isFirstPage,
}) => {
  const isCentered = isFirstPage || isLastPage;
  return (
    <div className={`flex flex-col items-center  ${
      isCentered
        ? "absolute bottom-6 left-1/2 transform -translate-x-1/2"
        : "absolute bottom-6 right-[130px]"
    }`}>
      <div className={`w-full flex flex-col ${isFirstPage ? "items-center" : "items-end"} gap-[8px]`}>

        <p className="text-white font-[400] text-[8px] md:text-[14px] opacity-[40%] text-center">
          {text}
        </p>
        <div className="flex w-full md:w-auto justify-stretch gap-[36px]">
          {isBack && (
            <button
              onClick={onButtonBackClick}
              className=" font-[600] text-[14px] md:text-[18px] h-[50px] md:h-[55px] basis-1/2 md:w-[190px] text-[#FFFFFF66] hover:text-[#ffffff2d] transition-all"
            >
              Назад
            </button>
          )}
          <button
            onClick={onButtonNextClick}
            className={`px-6 h-[50px] md:h-[55px] font-[600] text-[14px] md:text-[18px] bg-gradient-to-r from-[#0852FF] via-[#2C67F2] to-[#0852FF] border border-[#3E7BFF] text-white rounded-lg transition-all ${
              isBack ? "basis-1/2 md:w-[260px]" : "w-full max-w-[300px] mx-auto md:w-[300px]"
            }`}
          >
            {isLastPage ? "Отправить" : "Далее"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ButtonsBlock;
