import React, { useEffect, useState } from "react";
import owl from "../assets/owl.png";
import logo from "../assets/logo-small.png";

const Page4Content = ({ handleOptionChange }) => {
  const options = [
    {
      title: "Компьютерное зрение",
      description: "распознавание, классификация, сегментация",
    },
    {
      title: "Экспертная аналитика",
      description: "системы поддержки принятия решений",
    },
    {
      title: "Языковые модели",
      description: "распознавание, управление, оптимизация",
    },
    {
      title: "Умные цифровые помощники",
      description: "быстрые ответы, генерация текстов, изображений",
    },
    {
      title: "Персонифицированные операции",
      description: "управление процессами на основе аналитики поведения",
    },
    {
      title: "Интеграционные решения",
      description: "WEB сервисы, мобильные приложения, модули расширения",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleOption = (title) => {
    handleOptionChange((prevOptions) => ({
      ...prevOptions,
      [title]: !prevOptions[title],
    }));
  };

  const handleOptionClick = (title) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(title)
        ? prevSelected.filter((option) => option !== title)
        : [...prevSelected, title]
    );
  };

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[0px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white font-[600] text-[20px] text-center mb-[10px]">
          Технологии AI для вас
        </h1>
        <div className="max-w-[120px]">
          <img src={owl} alt="owl" />
        </div>
        <div className="max-w-[658px] text-white font-[400] text-[11px] mb-[12px] mt-[8px]">
          Мы создаем кастомизированные решения с технологиями AI:
        </div>

        <div className="flex justify-center gap-[10px] w-full flex-wrap">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option.title)}
              className={`flex flex-col items-start justify-end h-[85px] w-[48%] bg-[#15142A] p-[12px] rounded-[8px] cursor-pointer ${
                selectedOptions.includes(option.title)
                  ? "bg-gray-600"
                  : "bg-[#15142A]"
              }`}
            >
              <div className="text-white text-[11px] font-[600]">
                {option.title}
              </div>
              <div className="text-white text-[8px] font-[400] opacity-[40%]">
                {option.description}
              </div>
            </div>
          ))}
        </div>

        <div className="max-w-[658px] text-white font-[400] text-[12px] mt-[8px] mb-[10px]">
          Кликните в списке, какие технологии подойдут для вас?
        </div>
      </div>

      {/* Десктопная */}
      <div className="hidden md:flex justify-between items-start gap-[16px]">
        <div className="mt-[20px]">
          <h1 className="text-white font-[600] text-[28px] mb-[8px]">
            Технологии AI для вас
          </h1>
          <div className="max-w-[658px] text-white font-[500] text-[14px] mb-[16px]">
            Мы создаем кастомизированные решения с технологиями AI:
          </div>

          <div className="flex justify-start gap-[16px] w-full flex-wrap">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.title)}
                className={`flex flex-col items-start justify-end h-[110px] max-w-[240px]  w-[45%] bg-[#15142A] p-[16px] rounded-[8px] cursor-pointer ${
                  selectedOptions.includes(option.title)
                    ? "bg-gray-600"
                    : "bg-[#15142A]"
                }`}
              >
                <div className="text-white text-[16px] font-[600]">
                  {option.title}
                </div>
                <div className="text-white text-[12px] font-[400] opacity-[40%]">
                  {option.description}
                </div>
              </div>
            ))}
          </div>

          <div className="max-w-[658px] text-white font-[400] text-[14px] mt-[8px] mb-[12px]">
            Кликните в списке, какие технологии подойдут для вас?
          </div>
        </div>
        <div>
          <img src={owl} alt="owl" className="min-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page4Content;
