import React, { useEffect, useState } from "react";
import parrot from "../assets/parrot.png";
import logo from "../assets/logo-small.png";

const Page5Content = () => {
  const options = [
    {
      title: "Сокращение затрат на персонале",
      description:
        "Передача рутины роботам, у сотрудников больше времени на эффективные задачи",
    },
    {
      title: "Уменьшение затрат на риски",
      description:
        "Использование роботов, предупреждающих ошибки персонала, риски в процессах и решениях",
    },
    {
      title: "Экономия на обучении",
      description:
        "Алгоритмы и цифровые помощники сокращают время обучения и введение в процессы",
    },
    {
      title: "Экономия на маркетинге",
      description:
        "Формирование целевых коммуникаций и создание эффективной модели конверсии в цели",
    },
    {
      title: "Новая прибыль на процессах",
      description:
        "Создание новой экономики затрат за счет оптимизаций с сильным конкурентным преимуществом",
    },
    {
      title: "Новые неконкурентные проекты",
      description:
        "Создание цифровых сред с новыми технологиями, позволяющие создавать новые рынки",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (title) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(title)
        ? prevSelected.filter((option) => option !== title)
        : [...prevSelected, title]
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[4px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white font-[600] text-[20px] text-center mb-[0px]">
          Выгода  применения AI
        </h1>
        <div className="max-w-[90px]">
          <img src={parrot} alt="parrot" />
        </div>

        <div className="flex flex-col gap-[6px] max-w-[800px]">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option.title)}
              className={`flex items-center justify-start gap-[12px] pb-[6px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer 
                `}
            >
              <div
                className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                  selectedOptions.includes(option.title)
                    ? "bg-[#5EFF49]"
                    : "bg-gray-300"
                }`}
              ></div>
              <div className="flex flex-col items-start">
                <div className="text-white font-[600] text-[12px]">
                  {option.title}
                </div>
                <div className="text-white font-[400] text-[8px] opacity-[40%]">
                  {option.description}
                </div>
              </div>
            </div>
          ))}
          <div className="text-white font-[400] text-[12px] mt-[4px] mb-[8px]">
            Кликните в списке, какие выгоды подошли бы для вас?
          </div>
        </div>
      </div>

      {/* Десктоп */}
      <div className="hidden md:flex justify-start items-start  gap-[130px]">
        <div className="max-w-[832px] mt-[20px]">
          <h1 className="text-white font-[600] text-[28px] mb-[12px]">
            Выгода применения AI
          </h1>

          <div className="flex flex-col gap-[8px] max-w-[800px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.title)}
                className={`flex items-center justify-start gap-[12px] pb-[8px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer 
                  `}
              >
                <div
                  className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions.includes(option.title)
                      ? "bg-[#5EFF49]"
                      : "bg-gray-300"
                  }`}
                ></div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[16px]">
                    {option.title}
                  </div>
                  <div className="text-white font-[400] text-[12px] opacity-[40%]">
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[18px] mt-[10px] mb-[9px]">
            Кликните в списке, какие выгоды подошли бы для вас?
          </div>
        </div>
        <div>
          <img src={parrot} alt="parrot" className="min-w-[300px] max-w-[330px]" />
        </div>
      </div>
    </div>
  );
};

export default Page5Content;
