import React, { useEffect } from "react";
import turtle from "../assets/turtle.png";
import logo from "../assets/logo-small.png";

const Page7Content = ({ selectedOptions, handleOptionChange }) => {
  const options = [
    "Изучаем специфику и помогаем сформулировать задачу",
    "Анализируем эффективность существующих инструментов",
    "Проектируем эскизное решение задачи",
    "Оцениваем риски и трудоемкость задач по блокам",
    "Формулируем требования к данным, среде и пр.",
    "Оформляем в виде понятного документа",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[0px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white font-[600] text-center text-[20px] mb-[0px]">
          Получите решение
        </h1>
        <div className="max-w-[130px]">
          <img src={turtle} alt="turtle" />
        </div>
        <div className="text-white font-[400] text-[12px] max-w-[672px] mt-[6px] mb-[8px]">
          Мы создаем для вас персональный проект IT решений с AI, с оценкой
          эффективности! Это серьезная инженерная и маркетинговая задача.
        </div>

        <div className="flex flex-col items-start self-start">
          <div className="text-white font-[600] text-[14px] mb-[4px]">
            И мы это делаем сейчас бесплатно:
          </div>

          <div className="flex flex-col items-start self-start">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className="flex items-center gap-[12px] cursor-pointer py-[6px] border-b-[1px] border-white border-opacity-[15%]"
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[400] text-[10px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[600] text-[14px] mt-[8px] mb-[9px]">
            Задача, которую требуется решить
          </div>
        </div>
        <div className="w-full h-[42px] flex px-[12px] py-[12px] gap-[10px] border-[1px] rounded-[12px] mb-[18px]">
          <input
            type="text"
            placeholder="Опишите вашу задачу"
            className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[16px] "
          />
        </div>
      </div>

      {/* Десктоп */}
      <div className="hidden md:flex justify-between items-start gap-[16px]">
        <div className="max-w-[832px] mt-[20px]">
          <h1 className="text-white font-[600] text-[28px] mb-[8px]">
            Получите решение
          </h1>

          <div className="text-white font-[400] text-[14px] max-w-[500px] mb-[12px]">
            Мы создаем для вас персональный проект IT решений с AI, с оценкой
            эффективности! Это серьезная инженерная и маркетинговая задача.
          </div>

          <div className="text-white font-[600] text-[18px] mb-[6px]">
            И мы это делаем сейчас бесплатно:
          </div>

          <div className="flex flex-col items-start self-start max-w-[490px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className="flex w-full items-center gap-[12px] cursor-pointer py-[6px] border-b-[1px] border-white border-opacity-[15%]"
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[400] text-[12px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[600] text-[16px] mt-[12px] mb-[9px]">
            Задача, которую требуется решить
          </div>

          <div className="w-full xl:w-[490px] h-[80px] flex px-[12px] py-[8px] gap-[10px] border-[1px] rounded-[12px] mb-[14px]">
            <input
              type="text"
              placeholder="Опишите вашу задачу"
              className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px] "
            />
          </div>
        </div>
        <div>
          <img src={turtle} alt="turtle" className="min-w-[360px] max-w-[450px]" />
        </div>
      </div>
    </div>
  );
};

export default Page7Content;
