import React, { useEffect, useState } from "react";
import cheetah from "../assets/cheetah.png";
import logo from "../assets/logo-small.png";

const Page3Content = ({ handleOptionChange,  }) => {
  const options = [
    {
      title: "Убрали ненужные коммуникации",
      description:
        "Создали коммуникационный сервис для автоматизации бизнес процессов с цифровым умным помощником.",
    },
    {
      title: "Уменьшили риски ошибок",
      description:
        "Добавили умный функционал на сайт/сервис/приложение, упростивший операции и контролирующий риски в процессах.",
    },
    {
      title: "Получили новый уровень контроля",
      description:
        "Создали программу управления приборами/ процессами, с использованием нейросетей, контролирующий операции.",
    },
    {
      title: "Получили новое качество решений",
      description:
        "Создали вычислительный модуль с ИИ, позволяющий собирать и анализировать большие объемы данных и генерировать рекомендации.",
    },
    {
      title: "Получили экономию на ресурсах",
      description:
        "Интегрировали AI в существующие программные процессы, повысившие эффективность труда, и автоматизировав обучение новичков.",
    },
    {
      title: "Получили новые возможности",
      description:
        "Создали свой стартап с AI и получили новый рынок, конкурентное преимущество и у некоторых, даже новый бизнес.",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (title) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(title)
        ? prevSelected.filter((option) => option !== title)
        : [...prevSelected, title]
    );
  };

  // const handleOptionClick = (title) => {
  //    handleOptionChange(3, title);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="self-end mb-[4px]">
          <img src={logo} alt="logo" />
        </div>
        <h1 className="text-white font-[600] text-[18px] text-center mb-[10px]">
          Преимущества IT решений
        </h1>
        <div  className="max-w-[120px]">
          <img src={cheetah} alt="cheetah" />
        </div>
        <div className="text-white font-[400] text-[12px] mt-[8px] mb-[6px]">
          Посмотрите, за счет чего другие стали успешными:
        </div>

        {/* <div className="flex flex-col gap-[12px] max-w-[800px]"> */}
        <div className="flex flex-col gap-[6px] max-w-[800px]">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option.title)}
              className={`flex items-center justify-start gap-[8px] pb-[6px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer 
                `}
            >
              <div
                className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                  selectedOptions.includes(option.title)
                    ? "bg-[#5EFF49]"
                    : "bg-gray-300"
                }`}
              ></div>
              <div className="flex flex-col items-start">
                <div className="text-white font-[600] text-[12px]">
                  {option.title}
                </div>
                <div className="text-white font-[400] text-[10px] opacity-[40%]">
                  {option.description}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-white font-[400] text-[11px] mt-[14px] mb-[8px]">
          Кликнете на пункты, что бы подошло для вашего бизнеса/проекта?
        </div>
        <div className="text-white font-[400] text-[11px] mb-[10px] self-start">
          Для экспертов - наш стек технологий можно посмотреть{" "}
          <a
            href="https://example.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline hover:text-blue-700 transition-colors"
          >
            тут
          </a>
        </div>
      </div>
      {/* </div> */}

      {/* Десктоп */}
      <div className="hidden md:flex justify-between items-start gap-[62px]">
        <div className="max-w-[832px] mt-[20px]">
          <h1 className="text-white font-[600] text-[28px] mb-[8px]">
            Преимущества IT решений
          </h1>
          <div className="text-white font-[400] text-[14px] mb-[8px]">
            Посмотрите, за счет чего другие стали успешными:
          </div>

          <div className="flex flex-col gap-[6px] max-w-[800px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.title)}
                className={`flex items-center justify-start gap-[12px] pb-[6px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer 
                  `}
              >
                <div
                  className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions.includes(option.title)
                      ? "bg-[#5EFF49]"
                      : "bg-gray-300"
                  }`}
                ></div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[14px]">
                    {option.title}
                  </div>
                  <div className="text-white font-[400] text-[10px] opacity-[40%]">
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[13px] mt-[6px] mb-[6px]">
            Кликнете на пункты, что бы подошло для вашего бизнеса/проекта?
          </div>
          <div className="text-white font-[400] text-[13px] mb-[10px]">
            Для экспертов - наш стек технологий можно посмотреть{" "}
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700 transition-colors"
            >
              тут
            </a>
          </div>
        </div>
        <div>
          <img src={cheetah} alt="cheetah" className="min-w-[300px] max-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page3Content;
